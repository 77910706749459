/* stylelint-disable scss/dollar-variable-empty-line-before */

/* Light mode colors start */

/* Brand colors start */

// Primary colors
$primary: #46b8b0;
$primary-dark: #38938d;
$primary-light: #a2dcd8;
$primary-extra-light: #e3f4f3;
$on-primary: #fff;
$light-gray: #eff0f4;
$dark-gray: #aaa;
$medium-gray: #ddd;
$text-brand: #2f7a75;
$brand-bold: #00998f;
$brand-subtlest: #B8E5E2;

// Secondary colors
$secondary: #e39700;
$secondary-dark: #d38d00;
$secondary-container: #fbefd7;
$secondary-yellow: #ffea94;
$secondary-metal: #ccbb76;
$secondary-dark-yellow: #917100;
$text-disabled: #707ea0;
$loyalty-gold: #fbc56d;
$loyalty-blue: #05decf;

// Content colors
$content-main: #0f1933;
$content-medium: #707685;
$content-light: #a8acb5;
$gray-50: #f9fafb;
$dark-gray: #44506e;
$new-gray: #abb5d0;
$new-gray2: #F7F8FC;
$dark-slate-gray: #283041;

// Text Colors
$text-main: #fff;
$text-tertiary: #abb5d0;
$text-semantic-warning: #B84300;
$text-pending: #9E3A00;
$text-secondary-dark:#b8d3d6;
$text-on-dark:#F9FAFFF2;

// Semantic colors
$success: #36a64a;
$success-background: #d7eddb;

$warning: #e17605;
$dark-warning: #b85e00;
$warning-background: #f9e4cd;

$error: #ce0021;
$error-background: #f5ccd3;
$error-dark: #1d1d1d;
$error-maroon: #B0001C;

$info: #298cb6;
$info-background: #dfeef4;

$options: #8062d1;
$options-background: #e6e0f6;

// Background colors
$main-background: #fff;
$medium-background: #d9dbdf;
$light-background: #ecedef;
$extra-light-background: #f6f6f7;
$brand-light-background: #eaf4f3;
$neutral-normal: #191e29;
$light-info-background: #c3e5f3;
$button-brand-background: #008077;
$secondary-button-background: #cf8a00;
$light-gray-background: #ebeef7;
$dark-black-background: #0c0d12;
$inverse-background: #13161f;
$dark-blue-background: #091e4224;
$dark-green-background: #326662;
$new-green-background: #29515D;
$tier-background: #1f4f4c;
$tier-dark-background: #2E6763;
$strong-blue: #0D00FF;
$semantic-critical: #FFF0F2;

/* Brand colors end */

/* Status colors start */

// Orders statuses
$received-container: #e2f4f3;
$received-on-container: #38938d;

$suspended-container: #ecedef;
$suspended-on-container: #707685;

$in-progress-container: #f2e9fb;
$in-progress-on-container: #ad73e6;

$delivered-container: #b9e6c1;
$delivered-on-container: #257133;

$pending-container: #fbefd7;
$pending-on-container: #e39700;

$confirmed-container: #e1f4e4;
$confirmed-on-container: #3dbb54;

$cancelled-container: #f7d7dc;
$cancelled-on-container: #ce0021;

$return-in-progress-container: #ddedf4;
$return-in-progress-on-container: #298cb6;

// Products status
$available-container: #36a64a;
$limited-container: #e17605;
$out-of-stock-container: #ce0021;

/* Status colors end */

/* Social Colors start */

$facebook-container: #dbe9fb;
$facebook-on-container: #1a73e4;
$facebook-on-container-hover: #155db8;

$whatsapp-container: #ddf8e7;
$whatsapp-on-container: #1eb558;
$whatsapp-on-container-hover: #189146;

/* Social Colors end */

/* Other */
// OPT-IN
$female-pink: #ef5da8;
$male-blue: #298cb6;

$progress-bar-grey: #d9d9d9;
$question-text: #0b1725;
$black: #000;

// Dynamic-incentive
$dynamic-incentive: #193a45;
$dynamic-incentive-light: #214f5a;
$dynamic-incentive-number: #8d3d00;
$dynamic-incentive-yellow: #d89245;
$dynamic-incentive-maroon: #d84545;
$dynamic-incentive-reject: #5b0000;
$dynamic-incentive-red-pink: #ff9494;

// Customer Effor Score
$loaders-background-color: #bebebe;

// Custom material theme colors
$mat-form-error-color: #f44336;

/* Light mode colors end */

$box-shadow-light: #0000001a;

$border-heavy: #191e29;

$border-bundle: #7435b3;
$background-bundle: #f2e6ff;
$default-bundle: #340068;

$border-background: #fcfcff;

$border-brand: #04b3a7;
$background-brand: #e4f7f6;
$default-brand: #008077;

$border-secondary: #eeaf32;
$background-secondary: #fffaf0;
$default-secondary: #c27b00;
$default-secondary-light: #FDE5B4;

$border-positive: #37b954;
$background-positive: #cef0d5;
$default-positive: #00721d;

$light-border: #a6c5e229;
$primary-dark-header: #04b3a7;
$border-light: #bdc5db;

$info-blue: #0072a2;
$status-info-dark: #D94F00;
$status-cancelled: #FAC0C5;