:root {
  --light-blue-color: #2ec4b6;
  --light-green-color: #29B6AB;
  --background-color: #eff0f4;
  --dark-grey-text-color: #011626;
  --grey: #333333;
}


.card-header {
    text-align: center;
    padding-bottom: 15px;
}

.card-header h3 {
    font-weight: 500;
}

.card-title {
    margin-bottom: .75rem;
    padding-bottom: 1.3rem;
}

.card-text {
    padding-bottom: 1.3rem;
}

.mat-form-field-wrapper {
  background-color: none;
  width: 100% !important;
  height: 56px;
  padding: 0;
}

.mat-form-field-flex {
  background-color: none !important;
}

.mat-form-field-appearance-fill {
  background-color: none;
}

.mat-select-placeholder {
  background-color: none;
}

.mat-form-field-underline {
  display: none;
}

.signup-details-radio-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mat-radio-label-content {
  display: flex !important;
  align-items: center;
  gap: 10px;
}

.form-group {
    margin-bottom: 1rem;
}

.product-actions button{
    display: block;
    margin-bottom:1.6rem
}
.input-error{
    color:#ff0000;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 15px;
}
.form-error-message{
    text-align: center;
    margin-top: 12px;
}

.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #29B6AB;
    outline: 0;
    box-shadow: unset;
}

.form-group label {
    display: block;
    padding-bottom: 10px;
}

.btn-outline-info:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, .5);
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #333;
    border-color: #17a2b8;
}

.row {
    margin-top: 3.6rem;
}

.grid-item {
    padding: 50px;
    margin-bottom: 1.3rem;
}

.card-content{
    text-align: start;
}
.grid-item:hover {
    box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.3);
}
.badge-default{
    font-size: 17px;
    color:#ffffff;
    background-color: #1F8A81;
    font-weight: normal;
}

.page-height{
    min-height: 200px;
}

.ngx-gallery-arrow-left .ngx-gallery-arrow,
.ngx-gallery-arrow-right .ngx-gallery-arrow {
  width: 32px;
  height: 32px;
  background: no-repeat center;
  background-size: 15px;
  border-radius: 50%;
}

ngx-gallery-preview .ngx-gallery-arrow-right .ngx-gallery-arrow,
ngx-gallery-preview .ngx-gallery-arrow-left .ngx-gallery-arrow {
  background-color: var(--background-color);
}

.ngx-gallery-arrow-left .ngx-gallery-arrow {
  background-image: url('../../assets/img/prev_1.png');
}

.ngx-gallery-arrow-right .ngx-gallery-arrow {
  background-image: url('../../assets/img/next_1.png');
}

.filtersLabel{
    text-align: right;
}
.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: var(--light-blue-color);
}

.carousel-inner {
  padding: 0 0;
}

.carousel-control span {
  position: relative;
}

.carousel-control-prev-icon.icon-prev {
  background: url('../../assets/img/prev.svg') no-repeat center;
  cursor: pointer;
}


.carousel-control-next-icon.icon-next {
  background: url('../../assets/img/next.svg') no-repeat center;
  cursor: pointer;
}

.nav--scrolled .search-input {
  border: 1px solid #AAA;
}

.carousel.slide {
  outline: none;
}

.nav__links__mob-icon--active .icon {
  color: var(--light-green-color) !important;
}

.mat-checkbox-inner-container {
  width: 25px !important;
  height: 25px !important;
  margin-right: 10px !important;
}

.mat-checkbox-frame {
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
}

.mat-checkbox-checked .mat-checkbox-frame {
  border: 1px solid #29B6AB!important;
  background-color: #29B6AB;
  border-radius: 5px;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: transparent;
  border-radius: 5px;
}

.mat-checkbox-checkmark-path {
  stroke: #ffffff !important;
  width: 15.8px;
  height: 11.6px;
}


@media screen and (max-width:700px) {
  .grid-row {
    flex-direction: column;
  }

  .carousel-inner {
    padding: 0;
  }

}

iframe.hide-iframe {
  display: none;
}
