@import '_colors-light.scss';

/* stylelint-disable-next-line selector-class-pattern */
.wallet-transactions-filters__date-picker, .date-picker {
  .mat-form-field-wrapper {
    height: 52px;
  }

  &.mat-form-field-appearance-outline .mat-form-field-flex {
    align-items: center;
    margin-top: 0;
    padding: 8px;
  }

  .mat-form-field-outline-start,
  .mat-form-field-outline-end {
    visibility: hidden;
  }

  &.mat-form-field-appearance-outline .mat-form-field-flex .mat-form-field-prefix {
    top: 0;
  }

  .mat-icon-button.mat-button-base,
  .mat-calendar-body-cell.mat-calendar-body-active {
    outline: none;
  }

  .mat-form-field-infix {
    border-top: 0;
    width: 90px;
  }
}

.error {
  border-bottom: 2px solid $mat-form-error-color !important;
}
