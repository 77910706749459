@use "sass:math";

$desktop-navbar-min-vw: 992px;
$desktop-navbar-max-vw: 1920px;
$desktop-navbar-min-font-size: 12px;
$desktop-navbar-max-font-size: 16px;

$global-font-size: 100% !default;

/*
Removes the unit (e.g. px, em, rem) from a value, returning the number only.
@param {Number} $num - Number to strip unit from.
@returns {Number} The same number, sans unit.
*/

@function strip-unit($num) {
  @return math.div($num, $num * 0 + 1);
}

/*
Converts one or more pixel values into matching em values.
@param {Number|List} $values - One or more values to convert. Be sure to separate them with spaces and not commas. If you need to convert a comma-separated list, wrap the list in parentheses.

@param {Number} $base [null] - The base value to use when calculating the `em`. If you're using Foundation out of the box, this is 16px. If this parameter is `null`, the function will reference the `$base-font-size` variable as the base.

@returns {List} A list of converted values.
*/
@function em-calc($values, $base: null) {
  $em-values: ();
  $count: length($values);

  /* If no base is defined, defer to the global font size */
  @if $base == null {
    $base: $global-font-size;
  }

  /* If the base font size is a %, then multiply it by 16px
  This is because 100% font size = 16px in most all browsers
  */
  @if unit($base) == '%' {
    $base: math.div($base, 100%) * 16px;
  }

  /* Using em as base allows correct scaling */
  @if unit($base) == 'em' {
    $base: strip-unit($base) * 16px;
  }

  @if $count == 1 {
    @return -to-em($values, $base);
  }

  @for $i from 1 through $count {
    $em-values: append($em-values, -to-em(nth($values, $i), $base));
  }

  @return $em-values;
}

/*
Converts a pixel value to matching em value. *Any* value passed, regardless of unit, is assumed to be a pixel value. By default, the base pixel value used to calculate the em value is taken from the `$global-font-size` variable.

@access private
@param {Number} $value - Pixel value to convert.
@param {Number} $base [null] - Base for pixel conversion.

@returns {Number} A number in ems, calculated based on the given value and the base pixel value. em values are passed through as is.
*/
@function -to-em($value, $base: null) {
  /* Check if the value is a number */
  @if type-of($value) != 'number' {
    @warn inspect($value) + ' was passed to em-calc(), which is not a number.';
    @return $value;
  }

  /* Transform rem into em if someone hands over 'rem's */
  @if unit($value) == 'rem' {
    $value: strip-unit($value) * 1em;
  }

  /* Calculate em if units for $value is not em or rem */
  @if unit($value) != 'em' {
    $value: math.div(strip-unit($value), strip-unit($base)) * 1em;
  }

  /* Turn 0em into 0 */
  @if $value == 0em {
    $value: 0;
  }

  @return $value;
}

