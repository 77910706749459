@font-face {
    font-family: "icons";
    src: url("./icons.eot?eaa220cfed63f63bf8a5fdda92901733#iefix") format("embedded-opentype"),
url("./icons.woff2?eaa220cfed63f63bf8a5fdda92901733") format("woff2"),
url("./icons.woff?eaa220cfed63f63bf8a5fdda92901733") format("woff");
}

i:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

i.icon-001-exchange:before {
    content: "\f101";
}
i.icon-002-winner:before {
    content: "\f102";
}
i.icon-003-money:before {
    content: "\f103";
}
i.icon-account-navigation-icons-mobile-account-navigation-icons-account:before {
    content: "\f104";
}
i.icon-account-navigation-icons-mobile-account-navigation-icons-incentive:before {
    content: "\f105";
}
i.icon-account-navigation-icons-mobile-account-navigation-icons-Learn:before {
    content: "\f106";
}
i.icon-account-navigation-icons-mobile-account-navigation-icons-orders:before {
    content: "\f107";
}
i.icon-account-navigation-icons-mobile-account-navigation-icons-pre-order-requests:before {
    content: "\f108";
}
i.icon-account-navigation-icons-mobile-account-navigation-icons-wallet:before {
    content: "\f109";
}
i.icon-activation-toaster-icons-bell:before {
    content: "\f10a";
}
i.icon-active-complain-icon:before {
    content: "\f10b";
}
i.icon-active-suggest-icon:before {
    content: "\f10c";
}
i.icon-announcement-icon:before {
    content: "\f10d";
}
i.icon-auth-email:before {
    content: "\f10e";
}
i.icon-auth-female:before {
    content: "\f10f";
}
i.icon-auth-male:before {
    content: "\f110";
}
i.icon-auth-phone:before {
    content: "\f111";
}
i.icon-auth-profile:before {
    content: "\f112";
}
i.icon-auth-remove:before {
    content: "\f113";
}
i.icon-auth-store:before {
    content: "\f114";
}
i.icon-cart-icons-Customer-details:before {
    content: "\f115";
}
i.icon-cart-icons-Done:before {
    content: "\f116";
}
i.icon-cart-icons-new-cart:before {
    content: "\f117";
}
i.icon-cart-icons-shopping-cart:before {
    content: "\f118";
}
i.icon-cart-icons-Vector-not-active:before {
    content: "\f119";
}
i.icon-cart-icons-Vector:before {
    content: "\f11a";
}
i.icon-cart:before {
    content: "\f11b";
}
i.icon-category-icons-all-items:before {
    content: "\f11c";
}
i.icon-category-icons-Babies:before {
    content: "\f11d";
}
i.icon-category-icons-beauty:before {
    content: "\f11e";
}
i.icon-category-icons-Bride:before {
    content: "\f11f";
}
i.icon-category-icons-Car-Accessories:before {
    content: "\f120";
}
i.icon-category-icons-clothes:before {
    content: "\f121";
}
i.icon-category-icons-electronics:before {
    content: "\f122";
}
i.icon-category-icons-Fabrics:before {
    content: "\f123";
}
i.icon-category-icons-Fans-and-conditioners:before {
    content: "\f124";
}
i.icon-category-icons-Fragrances:before {
    content: "\f125";
}
i.icon-category-icons-Gaming:before {
    content: "\f126";
}
i.icon-category-icons-Home-Utensils:before {
    content: "\f127";
}
i.icon-category-icons-Kitchen:before {
    content: "\f128";
}
i.icon-category-icons-leather:before {
    content: "\f129";
}
i.icon-category-icons-Maintenance-tools:before {
    content: "\f12a";
}
i.icon-category-icons-Medical-Supplies:before {
    content: "\f12b";
}
i.icon-category-icons-mobile-accessiories:before {
    content: "\f12c";
}
i.icon-category-icons-Occasions:before {
    content: "\f12d";
}
i.icon-category-icons-Personal-Care-Appliances:before {
    content: "\f12e";
}
i.icon-category-icons-Pets-Supply:before {
    content: "\f12f";
}
i.icon-category-icons-Ramadan-products-2022:before {
    content: "\f130";
}
i.icon-category-icons-self-care:before {
    content: "\f131";
}
i.icon-category-icons-Shaver:before {
    content: "\f132";
}
i.icon-category-icons-shoes:before {
    content: "\f133";
}
i.icon-category-icons-small-electronic-devices:before {
    content: "\f134";
}
i.icon-category-icons-Smart-Watches:before {
    content: "\f135";
}
i.icon-category-icons-Sport:before {
    content: "\f136";
}
i.icon-category-icons-Taager-exclusive-offers:before {
    content: "\f137";
}
i.icon-category-icons-toys:before {
    content: "\f138";
}
i.icon-category-icons-watch:before {
    content: "\f139";
}
i.icon-check:before {
    content: "\f13a";
}
i.icon-clock:before {
    content: "\f13b";
}
i.icon-close:before {
    content: "\f13c";
}
i.icon-complain-icon:before {
    content: "\f13d";
}
i.icon-copy-2:before {
    content: "\f13e";
}
i.icon-copy:before {
    content: "\f13f";
}
i.icon-delete:before {
    content: "\f140";
}
i.icon-discount-lightning:before {
    content: "\f141";
}
i.icon-dollar:before {
    content: "\f142";
}
i.icon-download:before {
    content: "\f143";
}
i.icon-dropdown:before {
    content: "\f144";
}
i.icon-dukan-icons-back-en:before {
    content: "\f145";
}
i.icon-dukan-icons-back:before {
    content: "\f146";
}
i.icon-dukan-icons-contact:before {
    content: "\f147";
}
i.icon-dukan-icons-product:before {
    content: "\f148";
}
i.icon-edit-pen:before {
    content: "\f149";
}
i.icon-edit:before {
    content: "\f14a";
}
i.icon-excel-white:before {
    content: "\f14b";
}
i.icon-eye:before {
    content: "\f14c";
}
i.icon-form-dropdown-icon:before {
    content: "\f14d";
}
i.icon-header-icons-bulk:before {
    content: "\f14e";
}
i.icon-header-icons-chevron-down:before {
    content: "\f14f";
}
i.icon-header-icons-close:before {
    content: "\f150";
}
i.icon-header-icons-document:before {
    content: "\f151";
}
i.icon-header-icons-feedback:before {
    content: "\f152";
}
i.icon-header-icons-mobile-header-icons-bulk:before {
    content: "\f153";
}
i.icon-header-icons-mobile-header-icons-document:before {
    content: "\f154";
}
i.icon-header-icons-mobile-header-icons-feedback:before {
    content: "\f155";
}
i.icon-header-icons-mobile-header-icons-hamburger-menu:before {
    content: "\f156";
}
i.icon-header-icons-mobile-header-icons-performance-analytics:before {
    content: "\f157";
}
i.icon-header-icons-mobile-header-icons-products:before {
    content: "\f158";
}
i.icon-header-icons-mobile-header-icons-recommended:before {
    content: "\f159";
}
i.icon-header-icons-mobile-header-icons-Taager-t-mobile:before {
    content: "\f15a";
}
i.icon-header-icons-mobile-header-icons-ux-program:before {
    content: "\f15b";
}
i.icon-header-icons-mobile-header-icons-youtube:before {
    content: "\f15c";
}
i.icon-header-icons-nav-cart:before {
    content: "\f15d";
}
i.icon-header-icons-nav-catalog:before {
    content: "\f15e";
}
i.icon-header-icons-notification-bell:before {
    content: "\f15f";
}
i.icon-header-icons-performance-analytics:before {
    content: "\f160";
}
i.icon-header-icons-pie-chart:before {
    content: "\f161";
}
i.icon-header-icons-products:before {
    content: "\f162";
}
i.icon-header-icons-profile:before {
    content: "\f163";
}
i.icon-header-icons-recommended:before {
    content: "\f164";
}
i.icon-header-icons-youtube:before {
    content: "\f165";
}
i.icon-heart-active:before {
    content: "\f166";
}
i.icon-heart:before {
    content: "\f167";
}
i.icon-info:before {
    content: "\f168";
}
i.icon-landing-categories-accessories:before {
    content: "\f169";
}
i.icon-landing-categories-automotive:before {
    content: "\f16a";
}
i.icon-landing-categories-cosmetics:before {
    content: "\f16b";
}
i.icon-landing-categories-electronics:before {
    content: "\f16c";
}
i.icon-landing-categories-fashion:before {
    content: "\f16d";
}
i.icon-landing-categories-gifts:before {
    content: "\f16e";
}
i.icon-landing-categories-home:before {
    content: "\f16f";
}
i.icon-landing-categories-kids:before {
    content: "\f170";
}
i.icon-landing-categories-kitchen:before {
    content: "\f171";
}
i.icon-landing-categories-mobile-accessories:before {
    content: "\f172";
}
i.icon-landing-categories-pc-accessories:before {
    content: "\f173";
}
i.icon-landing-categories-pets:before {
    content: "\f174";
}
i.icon-landing-categories-shoes:before {
    content: "\f175";
}
i.icon-landing-categories-toys:before {
    content: "\f176";
}
i.icon-left-chevron:before {
    content: "\f177";
}
i.icon-lock:before {
    content: "\f178";
}
i.icon-magnifying-glass:before {
    content: "\f179";
}
i.icon-money-sign-dollar:before {
    content: "\f17a";
}
i.icon-next:before {
    content: "\f17b";
}
i.icon-nut:before {
    content: "\f17c";
}
i.icon-order-cancel:before {
    content: "\f17d";
}
i.icon-paper-plane:before {
    content: "\f17e";
}
i.icon-prev:before {
    content: "\f17f";
}
i.icon-product:before {
    content: "\f180";
}
i.icon-puzzles:before {
    content: "\f181";
}
i.icon-redirect:before {
    content: "\f182";
}
i.icon-responsive:before {
    content: "\f183";
}
i.icon-right-chevron:before {
    content: "\f184";
}
i.icon-setting:before {
    content: "\f185";
}
i.icon-slider-left:before {
    content: "\f186";
}
i.icon-slider-right:before {
    content: "\f187";
}
i.icon-smartphone:before {
    content: "\f188";
}
i.icon-suggest-icon:before {
    content: "\f189";
}
i.icon-tick:before {
    content: "\f18a";
}
i.icon-timer:before {
    content: "\f18b";
}
i.icon-track-order-icons-canceled:before {
    content: "\f18c";
}
i.icon-track-order-icons-checked:before {
    content: "\f18d";
}
i.icon-track-order-icons-clipboard:before {
    content: "\f18e";
}
i.icon-track-order-icons-clock:before {
    content: "\f18f";
}
i.icon-track-order-icons-completion-inprogress:before {
    content: "\f190";
}
i.icon-track-order-icons-completion-request:before {
    content: "\f191";
}
i.icon-track-order-icons-delivery-truck:before {
    content: "\f192";
}
i.icon-track-order-icons-inbox:before {
    content: "\f193";
}
i.icon-track-order-icons-refund-inprogress:before {
    content: "\f194";
}
i.icon-track-order-icons-refund-request:before {
    content: "\f195";
}
i.icon-track-order-icons-replacement-inprogress:before {
    content: "\f196";
}
i.icon-track-order-icons-replacement-request:before {
    content: "\f197";
}
i.icon-track-order-icons-shipped:before {
    content: "\f198";
}
i.icon-users-bubble:before {
    content: "\f199";
}
i.icon-wallet-money:before {
    content: "\f19a";
}
i.icon-wallet-date-picker-icon:before {
    content: "\f19b";
}
i.icon-wallet-transaction-history:before {
    content: "\f19c";
}
