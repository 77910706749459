@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'common.scss';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import 'quill-emoji/dist/quill-emoji.css';

@font-face {
  font-family: 'Taager';
  src: url(./fonts/Taager-Font/Taager-Regular.ttf);
}

body {
  letter-spacing: 0.05rem;
}

*,
.mat-raised-button,
.mat-dialog-title {
  font-family: 'Taager';
}

p {
  font-size: 1.6rem;
  font-weight: 400;
  color: rgba(11, 23, 37, 0.7);
}

h1 {
  font-size: 3.2rem;
  line-height: 3.2rem;
  font-weight: 600;
}

h2 {
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-weight: 500;
}

h3 {
  font-size: 2.8rem;
  line-height: 3.2rem;
  font-weight: 300;
  letter-spacing: 0.05rem;
}

h4 {
  font-size: 1.8rem;
  line-height: 1.8rem;
  font-weight: 300;
  margin-top: 0.8rem;
}

h5 {
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 300;
  margin-top: 0.8rem;
}

a {
  text-decoration: none;
  color: #29b6ab;
}

a:hover {
  text-decoration: underline;
  color: #38938d;
}

.txt-center {
  text-align: center;
}

.note {
  font-size: 1.8rem;
  display: inline-block;
}

.note--underline {
  border-bottom: 0.2rem solid #e80101;
}

.note--tertiary {
  color: #e80101;
}

.underline {
  border-bottom: 0.3rem solid;
  display: inline-block;
}

.underline--orange {
  border-color: #e80101;
}

.top-head {
  font-size: 5.6rem;
  line-height: 6.4rem;
  font-weight: 400;
}

.top-head:not(:last-child) {
  margin-bottom: 2.4rem;
}

@media (max-width: 991px) {
  .top-head {
    text-align: center;
  }
}

@media (max-width: 567px) {
  .top-head {
    font-size: 4.8rem;
    line-height: 5.6rem;
  }
}

.top-descripiton {
  font-size: 1.8rem;
  line-height: 2.4rem;
}

.top-descripiton:not(:last-child) {
  margin-bottom: 5.6rem;
}

@media (max-width: 991px) {
  .top-descripiton {
    text-align: center;
  }
}

.headled {
  margin-bottom: 1.6rem;
  font-weight: 600;
}

.headled--large {
  font-size: 3.2rem;
  line-height: 4rem;
}

@media (max-width: 567px) {
  .headled br {
    display: none;
  }
}

.subled {
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.8rem;
  line-height: 2.8rem;
}

.subled:not(:last-child) {
  margin-bottom: 6.4rem;
}

* {
  margin: 0;
  padding: 0;
  font-size-adjust: auto;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

html {
  box-sizing: border-box;
  font-size: 67%;
}

body {
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: -0.03em;
  color: #333;
}

.m-0 {
  margin: 0 !important;
}

.m-b-8 {
  margin-bottom: 0.8rem;
}

.m-b-16 {
  margin-bottom: 1.6rem;
}

.m-b-24 {
  margin-bottom: 2.4rem;
}

.m-b-32 {
  margin-bottom: 3.2rem;
}

.m-b-40 {
  margin-bottom: 4rem;
}

.m-b-48 {
  margin-bottom: 4.8rem;
}

.m-r-4 {
  margin-right: 0.4rem;
}

.m-r-8 {
  margin-right: 0.8rem;
}

.p-b-32 {
  padding-bottom: 3.2rem !important;
}

.p-b-40 {
  padding-bottom: 4rem !important;
}

input {
  font-size: 1.6rem;
}

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #aaa;
  font-weight: 400;
  box-sizing: border-box;
}

input::-moz-placeholder {
  /* Firefox 19+ */
  color: #aaa;
  font-weight: 400;
  box-sizing: border-box;
}

input:-ms-input-placeholder {
  /* IE 10+ */
  color: #aaa;
  font-weight: 400;
  box-sizing: border-box;
}

input:-moz-placeholder {
  /* Firefox 18- */
  color: #aaa;
  font-weight: 400;
  box-sizing: border-box;
}

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Webkit browsers like Safari and Chrome */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 4rem white inset;
  box-shadow: 0 0 0 4rem white inset;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-right {
  flex-direction: row-reverse;
  align-items: center;
}

.flex-left {
  flex-direction: row;
  align-items: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-space-between {
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}

@media (max-width: 767px) {
  .by-button-iframe {
    display: none !important;
  }
}

/* wasita watch link */
.wistia_click_to_play {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* hide/show user roles & credentials */
.deactivate {
  display: none;
}

.spinner {
  height: 30px;
}

hr {
  padding-bottom: 5.6rem;
  opacity: 0.2;
}

.cdk-overlay-container .mat-form-field {
  width: 100% !important;
}

.mat-form-field {
  font-size: 16px !important;
}

/* System polyfills  */
.form-control {
  font-size: 16px !important;
}

.form-control::-webkit-input-placeholder {
  color: $text-light;
  font-size: 16px;
}

@font-face {
  font-family: 'iconset';
  src: url('fonts/iconset.eot?klfnd1');
  src: url('fonts/iconset.eot?klfnd1#iefix') format('embedded-opentype'),
    url('fonts/iconset.ttf?klfnd1') format('truetype'),
    url('fonts/iconset.woff?klfnd1') format('woff'),
    url('fonts/iconset.svg?klfnd1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconset' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-hardware:before {
  content: '\e913';
}

.icon-chemical:before {
  content: '\e914';
}

.icon-construction:before {
  content: '\e915';
}

.icon-education:before {
  content: '\e916';
}

.icon-uniform:before {
  content: '\e917';
}

.icon-cleaning:before {
  content: '\e918';
}

.icon-lighting:before {
  content: '\e91a';
}

.icon-electronics:before {
  content: '\e91b';
}

.icon-environment:before {
  content: '\e91c';
}

.icon-food:before {
  content: '\e91d';
}

.icon-fuel:before {
  content: '\e91e';
}

.icon-medical:before {
  content: '\e91f';
}

.icon-hotel:before {
  content: '\e920';
}

.icon-manufacturing:before {
  content: '\e921';
}

.icon-office:before {
  content: '\e922';
}

.icon-marketing:before {
  content: '\e923';
}

.icon-mining:before {
  content: '\e924';
}

.icon-papers:before {
  content: '\e925';
}

.icon-printing:before {
  content: '\e926';
}

.icon-security:before {
  content: '\e927';
}

.icon-spare:before {
  content: '\e928';
}

.icon-transportation:before {
  content: '\e929';
}

.icon-premium:before {
  content: '\e90c';
}

.icon-play:before {
  content: '\e909';
}

.icon-star:before {
  content: '\e907';
}

.icon-tick-circle:before {
  content: '\e908';
}

.icon-menu:before {
  content: '\e906';
}

.icon-circle-down:before {
  content: '\e900';
}

.icon-circle-right:before {
  content: '\e901';
}

.icon-social-facebook:before {
  content: '\e902';
}

.icon-social-twitter:before {
  content: '\e903';
}

.icon-social-linkedin:before {
  content: '\e904';
}

.icon-home-search:before {
  content: '\e905';
}

.icon-arrow-down-circle:before {
  content: '\e90d';
}

.icon-chevron-left:before {
  content: '\e90a';
}

.icon-arrow-left-circle:before {
  content: '\e90e';
}

.icon-arrow-right-circle:before {
  content: '\e90f';
}

.icon-check-square:before {
  content: '\e910';
}

.icon-chevron-down:before {
  content: '\e911';
}

.icon-chevron-right:before {
  content: '\e90b';
}

.icon-home:before {
  content: '\e912';
}

.nav {
  background-color: #fff;
  width: 100%;
  min-height: 5.8rem;
  z-index: 999;
  /* nav modifiers */
}

.nav a {
  color: #0b1725;
  display: inline-block;
}

.nav a:hover {
  text-decoration: none;
}

.nav .container-custom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.nav__left {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .nav__left {
    flex: 25% 0 0;
  }
}

.nav__brand {
  margin-left: 2.4rem;
  position: relative;
  /* only when under logo status is active to fix the vertical alignment */
  /* under logo status */
}

.nav__brand a {
  position: relative;
}

.nav__brand img {
  width: 12.8rem;
}

@media (max-width: 767px) {
  .nav__brand img {
    width: 10rem;
  }
}

.nav__brand span {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  position: absolute;
  right: -0.1rem;
  top: 2.6rem;
  text-decoration: none;
  color: rgba(0, 26, 82, 0.3);
  text-transform: uppercase;
}

.nav__side {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav--top {
  background: #0b1725;
  color: #fff;
  border-bottom: 0;
  position: absolute;
  top: 0;
}

.nav--top .nav__brand span {
  color: #fff;
}

.nav--top img {
}

.nav--top .nav__links__mob-icon .icon {
  color: #fff;
}

.nav--top a {
  color: #fff;
}

.nav--scrolled .btn-custom {
  color: #fff;
}

.nav--scrolled .btn-custom--border-white {
  color: #0b1725;
  border-color: #0b1725;
}

.nav--scrolled .btn-custom--border-white:hover {
  border-color: #e80101;
}

.nav--fixed {
  position: fixed;
  top: 0;
}

.nav__links {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
}

.nav__links__mob-icon {
  display: none;
}

.nav__links ul {
  margin: 0;
  height: 100%;
}

.nav__links li {
  display: inline;
  cursor: pointer;
}

.nav__links li:not(:last-child) a {
  margin-right: 2rem;
  margin-left: 2rem;
}

.nav__links li:not(:last-child) .btn-custom {
  margin-right: 0.8rem;
}

.nav__links li:focus {
  outline: 0;
  box-shadow: none;
}

.nav__links li .button {
  font-size: 1.5rem;
}

@media (max-width: 767px) {
  .nav__links {
    position: relative;
    flex: 1;
  }

  .nav__links .nav__links__mobile {
    display: none;
    position: fixed;
    right: 0;
    top: 5.6rem;
    background: rgba(11, 23, 37, 0.95);
    z-index: 999;
    width: 100%;
    border-radius: 0;
    overflow: hidden;
    overflow-y: scroll;
  }

  .nav__links .nav__links__mobile li {
    display: block;
    padding: 1.6rem;
    margin: 0;
    text-align: center;
  }

  .nav__links .nav__links__mobile li a {
    color: #fff;
    display: block;
    border: 0.2rem solid #fff;
    padding: 1.6rem;
    margin: 0 !important;
    background: transparent;
  }

  .nav__links .nav__links__mobile li:hover a {
    background: white;
    color: #0b1725;
  }

  .nav__links .show {
    display: block;
  }

  .nav__links__mob-icon {
    cursor: pointer;
    display: block;
  }

  .nav__links__mob-icon .icon {
    font-size: 2.4rem;
    color: #0b1725;
  }

  .nav__links__mob-icon--active .icon {
    color: #e4c330 !important;
  }
}

.nav .nav__links__mob-icon--active .icon {
  color: #29b6ab;
}

@media (min-width: 768px) {
  /**
   * ----------------------------------------
   * animation slide-in-top
   * ----------------------------------------
   */
  @-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
      transform: translateY(-1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
      transform: translateY(-1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.btn-custom {
  padding: 0.8rem 2.4rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 400;
  border: 0.2rem solid transparent;
  border-radius: 8rem;
  letter-spacing: 0.05rem;
  cursor: pointer;
  color: #fff;
  display: inline-block;
}

.btn-custom:hover {
  text-decoration: none;
}

.btn-custom--blue {
  background-color: #29b6ab;
  color: #fff;
}

.btn-custom--blue:hover,
.btn-custom--blue:focus {
  background-color: #1f8a81;
}

.btn-custom--darkblue {
  background-color: #0b1725;
  color: #fff;
}

.btn-custom--darkblue:hover,
.btn-custom--darkblue:focus {
  background-color: #123863;
}

.btn-custom--orange {
  background-color: #e80101;
  color: #fff;
}

.btn-custom--orange:hover,
.btn-custom--orange:focus {
  background-color: #c6401a;
  color: #fff;
}

.btn-custom--yellow {
  background-color: #020202;
  color: #0b1725;
}

.btn-custom--yellow:hover,
.btn-custom--yellow:focus {
  background-color: #c7a71a;
  color: #0b1725;
}

.btn-custom--white {
  background-color: #fff;
  color: #0b1725;
}

.btn-custom--white:hover,
.btn-custom--white:focus {
  background-color: #c2c2c2;
}

.btn-custom--darkgrey {
  background-color: #1e1f2c;
  color: #fff;
}

.btn-custom--darkgrey:hover,
.btn-custom--darkgrey:focus {
  background-color: black;
}

.btn-custom--large {
  font-weight: 500;
  padding: 1rem 3.2rem;
  font-size: 2rem;
  line-height: 2.4rem;
}

.btn-custom--link {
  display: inline-block;
}

.btn-custom--link:hover {
  text-decoration: none;
}

.btn-custom--success {
  background: #32c68a;
}

.btn-custom--success:hover,
.btn-custom--success:focus {
  background: #269065;
}

.btn-custom--border-white {
  background: transparent;
  border: 0.2rem solid #fff;
  color: #fff;
  box-sizing: content-box;
}

.btn-custom--border-white:hover,
.btn-custom--border-white:focus {
  background: #fff;
  color: #29b6ab;
}

.btn-custom--border-blue {
  background: transparent;
  border: 0.2rem solid #29b6ab;
  color: #29b6ab;
  font-weight: 500;
  box-sizing: content-box;
}

.btn-custom--border-blue:hover,
.btn-custom--border-blue:focus {
  background: #29b6ab;
  color: #fff;
}

.btn-custom--no-action {
  background: grey;
  cursor: auto;
  opacity: 0.5;
}

@media (max-width: 567px) {
  .btn-custom {
    padding: 1.4rem 3.2rem;
    font-size: 1.6rem;
  }
}

@media (max-width: 991px) {
  .grid-gutter .col-md-6:not(:nth-last-of-type(-n + 2)) {
    margin-bottom: 2.4rem;
  }
}

@media (max-width: 767px) {
  .grid-gutter .col-md-6:not(:last-child) {
    margin-bottom: 3.2rem;
  }
}

@media (min-width: 768px) {
  .low-gutter-right {
    padding-right: 0 !important;
  }
}

@media (min-width: 1200px) {
  .big-right {
    margin-right: -3.2rem;
  }
}

@media (min-width: 1200px) {
  .big-left {
    margin-left: -3.2rem;
  }
}

.side-photo img {
  margin-right: -1.6rem;
  width: 100%;
  padding: 0 1.6rem;
}

@media (max-width: 991px) {
  .side-photo {
    display: none;
  }
}

@media (max-width: 767px) {
  .margin-mob:not(:last-child) {
    margin-bottom: 3.2rem;
  }
}

@media (min-width: 1200px) and (max-width: 1280px) {
  .container-custom {
    max-width: 98rem !important;
  }
}

.card {
  padding: 2.8rem 2.8rem;
  background-color: #fff;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem;
}

.card--dashed {
  border: 0.3rem dashed #29b6ab;
}

.nav__links li:not(:last-child) .btn-custom {
  margin-left: 1rem;
  margin-right: unset;
}

.nav__brand span {
  letter-spacing: unset !important;
}

.details-action .txt {
  top: unset !important;
}

.details-action .icon {
  margin-right: unset !important;
  margin-left: 0.8rem !important;
}

@media (min-width: 768px) {
  .low-gutter-right {
    padding-right: unset !important;
    padding-left: 0 !important;
  }
}

@media (min-width: 1200px) {
  .big-left {
    margin-left: unset !important;
    margin-right: -3.2rem;
  }

  .big-right {
    margin-right: unset !important;
    margin-left: -3.2rem;
  }
}

.quote {
  text-align: right !important;
}

@media (min-width: 1200px) {
  .quote-head {
    left: unset !important;
    right: -28rem;
  }
}

.quote .mark-bottom {
  left: unset !important;
  right: 0.6rem !important;
}

.quote .mark-top {
  right: -0.4rem !important;
  left: unset !important;
}

.contact__pp img {
  margin-right: unset !important;
  margin-left: 1.4rem !important;
}

.footer__col {
  margin-right: unset !important;
}

.footer__col:not(:last-child) {
  margin-left: 5.6rem;
}

.footer__left-side {
  margin-right: unset !important;
}

.footer__left-side .txt {
  line-height: 2.8rem !important;
}

.box .head {
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: 500 !important;
  margin-top: 3.2rem;
}

.box:hover .head,
.box:focus .head {
  font-weight: 500 !important;
}

@media (max-width: 767px) {
  .box {
    padding: 1.7rem 2.4rem 1.6rem 3.2rem !important;
  }

  .box .head {
    font-weight: 500 !important;
    font-size: 2.2rem !important;
    line-height: 3.2rem !important;
    margin-top: 0 !important;
  }
}

.box .show-items .icon {
  position: relative;
  top: 0.2rem;
}

@media (max-width: 767px) {
  .box .show-items .icon {
    position: absolute;
    right: unset;
    left: 0.4rem;
    top: calc(50% - 1rem);
  }
}

.box .items-extended {
  font-size: 1.6rem;
}

@media (max-width: 767px) {
  .box .items-extended {
    text-align: right;
  }
}

@media (max-width: 767px) {
  .box-container:hover .show-items,
  .box-container:focus .show-items {
    display: none;
  }
}

.footer {
  background: #08111c;
  padding: 8rem 0;
  color: #b0b0b0;
  line-height: 2.2rem;
  text-align: right;
}

.footer-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (min-width: 992px) {
  .footer-flex {
    flex-wrap: initial;
  }
}

.footer__left-side {
  flex: 0 0 100%;
}

@media (min-width: 992px) {
  .footer__left-side {
    flex: 0 0 50%;
    margin-right: 1.6rem;
  }
}

.footer__left-side .txt {
  font-size: 1.7rem;
}

.footer__right-side {
  flex: 0 0 100%;
}

@media (min-width: 992px) {
  .footer__right-side {
    flex: 0 0 40%;
    margin-right: 1.6rem;
  }
}

@media (min-width: 1200px) {
  .footer__right-side {
    flex: 0 0 40%;
    margin-right: 1.6rem;
  }
}

.footer__col {
  display: inline-block;
  vertical-align: top;
}

.footer__col:not(:last-child) {
  margin-right: 5.6rem;
}

@media (max-width: 767px) {
  .footer__col:not(:last-child) {
    margin-bottom: 1.6rem;
  }
}

.footer__col ul {
  list-style-type: none;
  color: rgba(11, 23, 37, 0.5);
  font-size: 1.5rem;
}

.footer__col ul li {
  margin-bottom: 1.4rem;
  cursor: pointer;
  letter-spacing: 0;
}

.footer__col ul a,
.footer__col ul a:visited {
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
}

.footer__col ul a:hover,
.footer__col ul a:focus,
.footer__col ul a:active {
  color: rgba(255, 255, 255, 0.4);
}

.footer__col ul .highlight a {
  color: #47d5ca;
}

.footer__col .main-list {
  color: #fff;
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 2rem;
  cursor: initial;
  padding-bottom: 1rem;
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.25);
}

.footer__brand {
  width: 11rem;
  display: block;
  margin: 0 0 3.2rem 0;
  opacity: 0.65;
}

.footer .logo-white {
  opacity: 1;
}

.footer .footer-head {
  font-size: 2rem;
}

.footer .footer-head:not(:last-child) {
  margin-bottom: 2.4rem;
}

.footer__social-box {
  font-size: 3.2rem;
}

.footer__social-box ul {
  margin: 0;
}

.footer__social-box li {
  display: inline;
  margin-left: 1.6rem;
}

.footer__social-box li a {
  color: #d9dde4;
}

.footer__social-box li a:visited {
  color: #d9dde4;
}

.footer__social-box li a.linkedin,
.footer__social-box li a.linkedin:visited {
  color: #1e69a0;
}

.footer__social-box li a.twitter,
.footer__social-box li a.twitter:visited {
  color: #24aad3;
}

.footer__social-box li a.facebook,
.footer__social-box li a.facebook:visited {
  color: #1b32e3;
}

.footer__social-box li a:hover,
.footer__social-box li a:active,
.footer__social-box li a:focus {
  color: rgba(41, 182, 171, 0.7);
  text-decoration: none;
}

@media (max-width: 767px) {
  .footer__social-box {
    margin-top: 1.6rem;
  }
}

.footer__contact {
  font-size: 2rem;
}

.footer__contact .data {
  font-weight: 300;
}

.footer__contact .data:not(:last-child) {
  margin-bottom: 1rem;
}

.footer__contact .value {
  margin-right: 2rem;
  font-weight: 600;
  letter-spacing: 0.2rem;
  color: #fff;
}

.footer__bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .footer {
    padding: 4rem 0;
  }
}

.footer--light {
  background: #fff !important;
  color: #0b1725;
}

.footer .copyrights {
  font-size: 1.8rem;
  color: #fff;
  padding: 2.4rem 0;
}

.main-banner-container {
  display: block;
  position: relative;
  padding: 8.8rem 0;
  min-height: 58rem;
  color: #fff;
  background: #0b1725;
}

@media (min-width: 992px) {
  .main-banner-container {
    text-align: right;
  }
}

@media (max-width: 767px) {
  .main-banner-container {
    padding-top: 12rem;
  }
}

.main-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 1;
  width: 100%;
}

.main-banner__ticker {
  margin-top: 3.2rem;
  list-style-type: none;
  display: inline-block;
}

.main-banner__ticker li {
  display: inline-block;
}

.main-banner__ticker li:not(:last-child) {
  padding-right: 1.4rem;
  margin-right: 1rem;
  border-right: 0.1rem solid rgba(98, 153, 255, 0.3);
}

.main-banner__ticker .icon {
  margin-right: 0.2rem;
  color: #bbf0ec;
}

@media (max-width: 419px) {
  .main-banner__ticker li {
    display: block;
    text-align: center;
  }

  .main-banner__ticker li:not(:last-child) {
    margin-bottom: 1rem;
    padding-right: 0;
    margin-right: 0;
    border-right: 0;
  }
}

@media (max-width: 991px) {
  .main-banner__ticker {
    padding: 1rem 2.6rem;
    border: 0.1rem solid rgba(98, 153, 255, 0.3);
    border-radius: 2rem;
  }
}

.main-banner__message {
  margin-bottom: 4rem;
}

@media (min-width: 768px) {
  .main-banner__message {
    max-width: 56rem;
  }
}

@media (max-width: 767px) {
  .main-banner__message {
    width: 100%;
  }
}

.main-banner__message h1 {
  font-size: 4.8rem;
  line-height: 5rem;
  font-weight: 500;
  color: #fff;
}

@media (max-width: 767px) {
  .main-banner__message h1 {
    font-size: 2.8rem;
    line-height: 3.2rem;
  }

  .main-banner__message h1 .txt-type {
    font-size: 3.2rem;
    line-height: 4.4rem;
  }
}

.main-banner__message h1 .txt-type {
  color: #fff;
  font-weight: 500;
  border-bottom: 0.2rem solid rgba(232, 1, 1, 0.8);
  padding-right: 0.2rem;
  padding-top: 0.4rem;
  margin-right: 1rem;
}

.main-banner__message h1:not(:last-child) {
  margin-bottom: 2.4rem;
}

.main-banner__message h2 {
  font-weight: 300;
  font-size: 2.8rem;
  line-height: 3.2rem;
  margin: auto;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 0.05rem;
}

@media (min-width: 992px) {
  .main-banner__message h2 {
    max-width: 56rem;
  }
}

@media (max-width: 767px) {
  .main-banner__message h2 {
    font-size: 1.8rem;
    line-height: 2.4rem;
    padding: 0 2rem;
  }
}

.main-banner__message h2:not(:last-child) {
  margin-bottom: 1rem;
}

.main-banner__action {
  position: relative;
  width: 100%;
  z-index: 10;
  margin-top: 1.6rem;
}

.main-banner__action .items {
  font-size: 1.4rem;
}

.main-banner__action h4 {
  margin-top: 3.6rem;
}

.main-banner__action .btn-custom {
  font-weight: 300;
}

@media (max-width: 991px) {
  .main-banner__action {
    text-align: center;
  }
}

.section-b {
  padding: 9.6rem 0;
  background: #f0f0f0;
  /* section-b modifiers */
}

@media (max-width: 767px) {
  .section-b {
    padding: 5.6rem 0;
  }
}

.section-b.negative-top {
  margin-top: -5.6rem;
}

.section-b__headbox {
  margin-bottom: 6.4rem;
  position: relative;
  z-index: 1;
}

.section-b__headbox h2 {
  font-size: 3.2rem;
  line-height: 3.2rem;
  font-weight: 600;
}

.section-b__headbox h2.color-blue {
  color: #1b7870;
}

.section-b__headbox--flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-b__headbox--flex img {
  width: 6.4rem;
}

.section-b__headbox--flex img:not(:last-child),
.user-bubble {
  margin-left: 2.4rem;
}

.section-b__headbox .colored {
  color: #e80101;
  font-weight: 600;
}

.section-b__headbox .head-mini {
  font-size: 2.6rem;
  line-height: 2.8rem;
  font-weight: 400;
}

.section-b__headbox h3 {
  font-size: 2rem;
  font-weight: 400;
  color: rgba(11, 23, 37, 0.65);
}

.section-b__headbox h3.color-blue {
  color: #1b7870;
}

.section-b__headbox p {
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: rgba(11, 23, 37, 0.7);
  max-width: 68rem;
  margin: auto;
}

.section-b__headbox p:not(:first-child) {
  margin-top: 1.6rem;
}

.section-b__headbox--mini {
  margin-bottom: 4rem;
}

.section-b__headbox--large {
  margin-bottom: 10.4rem;
}

@media (max-width: 767px) {
  .section-b__headbox {
    padding: 0 2.4rem;
    margin-bottom: 3.2rem;
  }

  .section-b__headbox h2 {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  .section-b__headbox h3 {
    font-size: 1.8rem;
  }

  .section-b__headbox p {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}

.section-b__action h2 {
  font-size: 4rem;
  line-height: 4.2rem;
  margin-bottom: 1.6rem;
}

.section-b__action h3 {
  font-size: 2rem;
  line-height: 2.4rem;
  opacity: 0.8;
  font-weight: 400;
}

.section-b__action h3:not(:last-child) {
  margin-bottom: 4rem;
}

.section-b__action .btn-custom:not(:last-child) {
  margin-bottom: 1.6rem;
}

.section-b--grey {
  background: #dadee7;
}

.section-b--greyblue {
  background: #dfedf9;
  color: #0b1725;
}

.section-b--midgrey {
  background: #e2e6ed;
}

.section-b--darkgrey {
  background: #333;
  color: #fff;
}

.section-b--white {
  background: #fff;
}

.section-b--light-blue {
  background: #e8f8f7;
}

.section-b--dark {
  background: #000a1e;
  color: #fff;
}

.section-b--darkblue {
  background: #0b1725;
  color: #fff;
}

.section-b--primary {
  background: #21958c;
  color: #fff;
}

.section-b--primary p {
  color: #fff;
}

.section-b--mini {
  padding: 8rem 0;
}

.section-b--strip {
  padding: 4rem 0;
}

.section-b--border {
  border-top: 0.2rem solid rgba(0, 0, 0, 0.08);
}

.section-b--mixed {
  position: relative;
  overflow: hidden;
}

.section-b--mixed .mixed-light {
  color: #0b1725;
}

.section-b--mixed .mixed-dark h1,
.section-b--mixed .mixed-dark h2,
.section-b--mixed .mixed-dark h3 {
  color: #fff;
}

.section-b--mixed::after {
  content: '';
  background: #fff;
  height: 42rem;
  top: 0;
  left: 0;
  bottom: unset;
  right: 0;
  position: absolute;
  z-index: 0;
}

@media (min-width: 1660px) {
  .section-b--mixed::after {
    height: 48rem;
    top: -4.8rem;
  }
}

.section-b--action h2 {
  font-size: 3.6rem;
  line-height: 4rem;
}

.section-b--action h2:not(:last-child) {
  margin-bottom: 1.6rem;
}

.section-b--action .description {
  font-size: 2.4rem;
  font-weight: 300;
}

.section-b--action .description:not(:last-child) {
  margin-bottom: 2.4rem;
}

.section-b--bg {
  position: relative;
}

.section-b--bg .relative-content {
  position: relative;
  z-index: 1;
}

.section-b--bg:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  background-image: url(../img/pattern.png);
  background-repeat: repeat;
  height: 100%;
  background-size: 40rem;
  opacity: 0.15;
}

@media (max-width: 991px) {
  .section-b .underline {
    border: 0;
    text-decoration: underline;
  }
}

.about-note {
  text-align: right;
}

.about-note__head {
  display: inline-block;
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 1.6rem;
  border-bottom: 0.8rem solid #4fb7ac;
}

.about-note:not(:last-child) {
  margin-bottom: 5.6rem;
}

@media (max-width: 991px) {
  .about-note {
    text-align: center;
  }
}

.item-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 0.8rem;
  margin-bottom: 3.2rem;
  position: relative;
  color: #0b1725;
  padding: 4.8rem 3.2rem 3.2rem 3.2rem;
  box-shadow: 0 0 2rem 0.32rem rgba(0, 0, 0, 0.14);
  z-index: 1;
  border-top: 0.8rem solid #29b6ab;
}

@media (max-width: 991px) {
  .item-card {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
}

@media (max-width: 767px) {
  .item-card {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
}

@media (min-width: 768px) {
  .item-card {
    min-height: 27rem;
  }
}

@media (min-width: 992px) {
  .item-card {
    min-height: 29rem;
  }
}

@media (min-width: 1200px) {
  .item-card {
    min-height: 27rem;
  }
}

.item-card__stage {
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 2.2rem;
  background: #0b1725;
  color: #fff;
  font-weight: 600;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -7.2rem;
  margin-bottom: 3.2rem;
}

.item-card__stage .number {
  position: relative;
  top: 0.1rem;
  font-weight: 400;
}

.item-card__top {
  margin-bottom: 2.4rem;
}

.item-card__info h3 {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.4rem;
}

.item-card__info h3:not(:last-child) {
  margin-bottom: 2.4rem;
}

.item-card__info .description {
  color: #3b3d55;
  line-height: 2.2rem;
  font-size: 1.7rem;
}

@media (min-width: 768px) {
  .item-card__info {
    min-height: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.item-card:hover {
  -webkit-transition: box-shadow 0.5s;
  /* Safari prior 6.1 */
  transition: box-shadow 0.5s;
  box-shadow: unset;
  background: #fafefd;
}

.item-card:hover .item-card__stage {
  background: #29b6ab;
}

.item-card--quote {
  padding: 5.6rem 4rem 4rem 4rem;
  margin-bottom: 1.6rem;
  margin-top: 0;
}

.item-card--quote:hover,
.item-card--quote:focus {
  background: #f1fcff;
}

.item-card--quote:hover .company-logo img,
.item-card--quote:focus .company-logo img {
  opacity: 1;
}

@media (min-width: 768px) and (max-width: 991px) {
  .item-card--step {
    padding: 2rem 1rem 1rem 1rem !important;
    min-height: 32rem;
  }

  .item-card--step img {
    height: 8rem;
  }

  .item-card--step h3 {
    margin-bottom: 4rem;
  }
}

.quote-box {
  color: #0b1725;
  height: 18rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.quote-box__description {
  font-size: 2rem;
  line-height: 2.8rem;
  font-weight: 600;
}

.quote-box__description .quote-mark {
  color: #29b6ab;
  font-size: 3.2rem;
  padding: 0.4rem;
}

.quote-box__description:not(:last-child) {
  margin-bottom: 2.4rem;
}

.quote-box__author {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: right;
  font-size: 1.6rem;
  line-height: 2rem;
}

.quote-box__author img {
  border-radius: 8rem;
  width: 4rem;
  height: 4rem;
}

.quote-box__author img:not(:last-child),
.quote-box-img {
  margin-left: 1rem;
}

.quote-box__author .name {
  font-weight: 600;
}

@media (max-width: 991px) {
  .quote-box {
    height: 28rem;
  }
}

@media (max-width: 767px) {
  .quote-box {
    height: auto;
  }
}

.category h2 {
  font-size: 2rem;
  line-height: 2.4rem;
  margin-bottom: 2.4rem;
}

.category p,
.category li {
  line-height: 2.2rem;
  font-size: 1.5rem;
  max-width: 32rem;
  margin: auto;
}

.category ul {
  list-style-type: none;
  opacity: 0.7;
}

.category ul li {
  padding: 0.2rem 0;
  margin: initial;
}

.category ul li:not(:last-child) {
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
}

.category--dark p {
  color: rgba(255, 255, 255, 0.65);
  letter-spacing: initial;
}

@media (max-width: 767px) {
  .category {
    text-align: center;
  }

  .category ul > li {
    margin: auto;
  }

  .category:not(:last-child) {
    margin-bottom: 4.4rem;
  }
}

@media (max-width: 991px) {
  .category {
    margin-bottom: 2.4rem;
  }
}

.slider-container {
  position: relative;
  transform: scale(0.8);
}

@media (min-width: 1200px) and (max-width: 1280px) {
  .slider-container {
    transform: scale(0.75);
  }
}

@media (min-width: 1200px) and (max-width: 1280px) {
  .slider-container {
    transform: scale(0.75);
  }
}

.slider-container .slider-item {
  width: 25rem;
  position: absolute;
  top: 0;
  left: calc(50% - 14rem);
  text-align: center;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.slider-container .slider-item .card {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.slider-container .slider-item img {
  margin: -8.8rem auto 1.6rem auto;
}

.slider-container .slider-item__title {
  font-weight: 600;
  font-size: 2.4rem;
}

.slider-container .slider-item__title:not(:last-child) {
  margin-bottom: 1rem;
}

.slider-container .slider-item__subtitle {
  color: rgba(11, 23, 37, 0.5);
  font-size: 2rem;
}

.slider-container .slider-item__subtitle:not(:last-child) {
  margin-bottom: 1rem;
}

.slider-container .slider-item__value {
  font-weight: 600;
  font-size: 2.4rem;
}

.slider-container .slider-item .slider-item__profit-box {
  background: transparent;
  padding: 1.4rem 0.8rem;
  font-size: 2rem;
  border-bottom-right-radius: 1rem;
  color: #fff;
  border-bottom-left-radius: 1rem;
}

.slider-container .slider-item .slider-item__profit-box span {
  margin: 0 0.4rem;
  font-size: 3.2rem;
  letter-spacing: 0.1rem;
  font-weight: 600;
}

.slider-container .slider-item--active {
  color: #0b1725;
  z-index: 100;
  box-shadow: 0 0 3.2rem 1rem #000;
}

.slider-container .slider-item--active .slider-item__profit-box {
  background: #22a89e;
}

.slider-container .slider-item--right {
  position: absolute;
  top: -4.8rem;
  right: -4.8rem;
  z-index: 0;
}

.slider-container .slider-item--right .card {
  background: transparent;
  color: #fff;
  border: 0.2rem solid #29b6ab;
}

.slider-container .slider-item--right .slider-item__profit-box {
  border: 0.2rem solid #29b6ab;
  border-top: 0;
  background: transparent;
}

.slider-container .slider-item--right .slider-item__subtitle {
  color: rgba(41, 182, 171, 0.6);
}

.slider-container .slider-item--left {
  position: absolute;
  top: 4.8rem;
  left: -4.8rem;
  z-index: 0;
}

.slider-container .slider-item--left .card {
  background: transparent;
  color: #fff;
  border: 0.2rem solid #29b6ab;
}

.slider-container .slider-item--left .slider-item__profit-box {
  border: 0.2rem solid #29b6ab;
  border-top: 0;
  background: transparent;
}

.slider-container .slider-item--left .slider-item__subtitle {
  color: rgba(41, 182, 171, 0.6);
}

.slider-container .slider-item:hover {
  z-index: 101;
  box-shadow: 0 0 3.2rem 1rem rgba(0, 0, 0, 0.2);
}

.slider-container .slider-item:hover .card {
  background: #fff;
  color: #0b1725;
  border: 0;
  -webkit-transition: background 0.6s;
  /* Safari prior 6.1 */
  transition: background 0.6s;
}

.slider-container .slider-item:hover .slider-item__profit-box {
  background: #22a89e;
  color: #fff;
}

.slider-container .slider-item:hover .slider-item__subtitle {
  color: rgba(11, 23, 37, 0.5);
}

.slider-container .slider-item:hover ~ .slider-item--active .card {
  background: #0b1725;
  color: #fff;
  border: 0.2rem solid #29b6ab;
}

.slider-container .slider-item:hover ~ .slider-item--active .slider-item__profit-box {
  border: 0.2rem solid #29b6ab;
  border-top: 0;
  background: #0b1725;
}

@media (max-width: 991px) {
  .main-banner-container .mobile-first {
    order: -1;
  }

  .main-banner-container .slider-container {
    height: 30rem;
  }

  .main-banner-container .slider-container .slider-item--left {
    top: -5.6rem;
    left: 5.6rem;
  }

  .main-banner-container .slider-container .slider-item--right {
    top: -5.6rem;
    right: 5.6rem;
  }
}

@media (max-width: 767px) {
  .main-banner-container .slider-container {
    height: 30rem;
  }

  .main-banner-container .slider-container .slider-item--left {
    top: -5.6rem;
    left: -6.4rem;
  }

  .main-banner-container .slider-container .slider-item--right {
    top: -5.6rem;
    right: -5.6rem;
  }
}

@media (max-width: 567px) {
  .main-banner-container .slider-container {
    transform: scale(0.6);
  }

  .main-banner-container .slider-container .slider-item--left {
    top: 0;
    left: -8rem;
  }

  .main-banner-container .slider-container .slider-item--left:hover {
    transform: scale(1.2);
  }

  .main-banner-container .slider-container .slider-item--right {
    top: 0;
    right: -8rem;
  }

  .main-banner-container .slider-container .slider-item--right:hover {
    transform: scale(1.2);
  }

  .main-banner-container .slider-container .slider-item--active {
    transform: scale(1.3);
  }
}

/*!
   * Bootstrap Grid v4.1.0 (https://getbootstrap.com/)
   * Copyright 2011-2018 The Bootstrap Authors
   * Copyright 2011-2018 Twitter, Inc.
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
   */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container-custom {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-custom {
    max-width: 54rem;
  }
}

@media (min-width: 768px) {
  .container-custom {
    max-width: 72rem;
  }
}

@media (min-width: 992px) {
  .container-custom {
    max-width: 96rem;
  }
}

@media (min-width: 1200px) {
  .container-custom {
    max-width: 114rem;
  }
}

.container-fluid-custom {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.top-banner {
  .carousel-arrow-prev {
    left: -50px !important;
    box-shadow: none !important;

    @media (max-width: 1600px) {
      left: -60px;
      width: 60px;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .carousel-arrow-next {
    right: -50px !important;
    box-shadow: none !important;

    @media (max-width: 1600px) {
      right: -60px;
      width: 60px;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .carousel-dots {
    bottom: -35px !important;

    @media (max-width: 768px) {
      bottom: -30px;
    }
  }

  .carousel-dot {
    background-color: #dddddd;
    border-radius: 30px !important;
    margin: 0 5px !important;
    height: 10px !important;
    width: 10px !important;
    border: none !important;

    @media (max-width: 768px) {
      margin: 0 3px !important;
      height: 6px !important;
      width: 6px !important;
    }
  }
  .carousel-dot-active {
    background-color: #29b6ab !important;
    border-radius: 30px !important;
    margin: 0 5px !important;
    height: 10px !important;
    width: 20px !important;
    border: none !important;

    @media (max-width: 768px) {
      height: 6px !important;
      margin: 0 3px !important;
      width: 13px !important;
    }
  }
}

.product-gallery {
  .carousel-arrow-prev {
    background: url('/src/assets/img/left-icon-stroke.svg') no-repeat center !important;
    box-shadow: none !important;
  }

  .carousel-arrow-next {
    background: url('/src/assets/img/right-icon-stroke.svg') no-repeat center !important;
    box-shadow: none !important;
  }
}

.products-carousel {
  .carousel-arrow-prev {
    background: url('../img/left-icon-green.svg') no-repeat center !important;
    height: 60px;
    width: 60px;

    @media (max-width: 1600px) {
      left: -40px !important;
      width: 60px;
    }

    @media (max-width: 991px) {
      display: none;
    }
  }

  .carousel-arrow-next {
    background: url('../img/right-icon-green.svg') no-repeat center !important;
    height: 60px;
    width: 60px;

    @media (max-width: 1600px) {
      right: -40px !important;
      width: 60px;
    }

    @media (max-width: 900px) {
      display: none;
    }
  }

  // .carousel-cells .carousel-cell img {
  //   width: 100 !important;
  // }
}

.carousel-container {
  display: flex;
  direction: ltr;
}
html[dir='ltr'] .mat-datepicker-popup {
  direction: ltr !important;
}
html[dir='ltr'] .mat-calendar-previous-button {
  transform: rotate(0);
}
html[dir='ltr'] .mat-calendar-next-button {
  transform: rotate(0);
}

html[dir='ltr'] .carousel-container {
  justify-content: flex-start;
}
html[dir='rtl'] .carousel-container {
  justify-content: flex-end;
}

html[dir='ltr'] .min-card-label {
  font-size: 13px !important;
}

html[dir='ltr'] .card-container .stock-availability {
  flex-direction: row-reverse;
}

html[dir='ltr'] .footer__left-side .txt {
  text-align: start;
}

html[dir='ltr'] .footer__social-box ul {
  justify-content: flex-end;
}

html[dir='ltr'] .footer__contact .value {
  margin-right: 0;
}

html[dir='rtl'] .tooltip-wrapper .icon {
  transform: rotateY(180deg);
}

html[dir='ltr'] .footer_wrapper {
  direction: rtl !important;
}

.smartbanner {
  position: fixed !important;
}
.smartbanner.smartbanner--android {
  background: none !important;
  background-color: $app-banner-background !important;
  .smartbanner__exit {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
  .smartbanner__exit::after,
  .smartbanner__exit::before {
    top: 2 !important;
    height: 14px !important;
    background: white !important;
  }
  .smartbanner__info {
    justify-content: center !important;
    align-items: flex-start;
    margin: 0 8px 0 !important;
    .smartbanner__info--author {
      display: none;
    }
  }
  .smartbanner__button {
    border: none !important;
    padding: 10px !important;
    padding-bottom: 7px !important;
    border-radius: 5px !important;
    background-color: $primary-color !important;
  }
}

/**
No need to customize questionnaire progress bar, based on RTL or LTR
as it will be switched automatically by the browser.
**/
.questionnaire-customized-progress-bar {
  height: 7px !important;
  .mat-progress-bar-fill:after {
    background-color: #46b8b0;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }
}

/** Style the Meta Phone Number Menu**/
.meta-phone-number-countries-menu {
  $menu-height: 300;
  min-width: 100px !important;
  // max-width: max-content !important;
  max-height: calc($menu-height * 1px) !important;
  overflow-x: hidden !important;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #46b8b0;
    border-radius: 4px;
  }

  .mat-menu-content {
    height: 100%;
    width: 100%;
    padding: 0 !important;
  }
}

html[dir='ltr'] {
  .shared-layout-container {
    .sidebar {
      margin: em-calc(70px 0 0 70px) !important;
    }
  }
  .incentive-container__accordion__cards,
  .incentive-container__accordion__cards__left {
    flex-direction: row-reverse;
  }
}

[dir='ltr'] .inline-div,
[dir='ltr'] .table,
[dir='ltr'] .title,
[dir='ltr'] .mat-dialog-content,
[dir='ltr'] .wrapper p {
  direction: ltr !important;
}

.loading-container {
  height: 70vh;
}

.backdrop-bg {
  background: rgb(15 25 51 / 80%);
}

.form-control-error {
  color: #e80101;
}

.notifications-dropdown-menu.mat-menu-panel {
  margin-top: 17px;
  max-width: none;
  width: 381px;
  max-height: none;
  border-radius: 8px;
  box-shadow: 0px 0px 28px 0px #11111136;
}

.notifications-dropdown-menu .mat-menu-content {
  padding: 0 !important;
}
