@import 'common.scss';
@import '_colors-light.scss';
@import 'src/app/presentation/wallet/wallet-transactions-history-filters/wallet-transaction-override.scss';
@import 'src/app/presentation/wallet/wallet-transaction-history/pagination-styling-override.scss';

angular2-multiselect {
  .c-btn {
    border: 1px solid $medium-gray;
    border-radius: 10px;
    font-size: 16px;
    height: 45px;
    span {
      color: $dark-gray;
    }
    .c-angle-down,
    .c-angle-up,
    .c-remove {
      display: none;
    }
    .c-remove.clear-all {
      display: block;
      top: 55%;
      left: 15px;
      right: unset;
    }
    .c-list .c-token {
      float: right;
      padding: 4px 0;
    }
    .c-label {
      color: $secondary-color;
    }
  }
  .dropdown-list {
    .arrow-down,
    .arrow-up {
      margin-left: 0;
      margin-right: 20px;
    }
    label {
      padding: 10px;
    }
    .lazyContainer {
      width: 100%;
    }
    .pure-checkbox {
      display: flex;
      label {
        display: none;
      }
      &:hover {
        background-color: $primary-color;
        color: $white;
      }
    }
  }
  .list-area {
    overflow: hidden;
    .list-filter {
      height: 45px;
      .c-search {
        top: 33%;
      }
      .c-input {
        padding-right: 10px;
      }
      .c-clear {
        display: none;
      }
    }
  }
}
// override landing page expansion panel
.question__expansion-panel {
  .mat-content {
    align-items: flex-start;
  }
  .mat-expansion-panel-header-title,
  .mat-expansion-panel-header-description {
    margin-left: 0 !important;
  }
}

/**
 Override app-faq last child to be bottom margin 0, for all devices, so that
 it does not skew the visual overlay of the page.
**/
app-faq {
  app-question-box {
    &:last-of-type {
      mat-expansion-panel {
        margin-bottom: 0 !important;
      }
    }
  }
}

app-cart {
  mat-progress-spinner.second-product-discount__progress-spinner circle {
    stroke: $options;
  }
  mat-progress-spinner.second-product-discount__progress-spinner-background circle {
    stroke: $white;
  }
}

.order-summary-country-selection-menu-wrapper {
  .mat-menu-content {
    padding-bottom: 0 !important;
  }
}

.bulk-errors-dialog .mat-dialog-container {
  border-radius: 0;
}

.learning-center {
  .mat-expansion-panel-body {
    padding: 0 !important;
  }
  .mat-expansion-panel-header-title,
  .mat-expansion-panel-header-description {
    flex-basis: unset;
    flex-grow: unset;
  }
}

.stores-container {
  .mat-expansion-panel {
    box-shadow: none !important;
  }
  .mat-expansion-panel-header {
    width: fit-content;
  }
  .mat-expansion-indicator:after {
    color: $primary-dark;
  }
}

.setup-dukan {
  .mat-checkbox-inner-container {
    margin-inline-start: 0 !important;
    width: 20px !important;
    height: 20px !important;
  }
}

.linked-store-dialog .mat-dialog-container {
  border-radius: 20px;
}

.dynamic-incentive {
  .mat-progress-bar-fill::after {
    background: linear-gradient(
      153.42deg,
      #ffea94 -9.21%,
      #d89245 166.32%,
      #c5661d 254.08%
    ) !important;
  }
  .mat-progress-bar-buffer {
    background-color: $dynamic-incentive !important;
  }
}

.locked-quantity-container {
  .mat-progress-bar-buffer {
    background-color: $delivered-container !important;
  }
  .mat-progress-bar-fill::after {
    background-color: $delivered-on-container !important;
  }
}

app-toggle-product-added {
  .mat-slide-toggle-bar {
    background-color: $medium-background;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: $primary;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $on-primary;
  }

  .mat-slide-toggle.mat-checked .mat-ripple-element {
    background-color: $primary;
  }
}

button.mat-calendar-body-cell.mat-calendar-body-active {
  outline: none;
}

.mat-calendar-body-selected {
  background-color: $primary;
}

.mat-calendar-body-in-range::before,
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background: $primary-extra-light;
}

.dukan-page {
  .mat-tab-labels {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  .mat-tab-label {
    justify-content: flex-start;
    padding: 0 10px;
    i {
      margin-inline-end: 10px;
      color: $content-medium;
      font-size: 18px;
    }
  }
  .mat-tab-group.mat-primary .mat-ink-bar {
    display: none;
  }
  .mat-tab-header {
    border-bottom: none;
    height: fit-content;
    border: 1px solid $medium-background;
    border-radius: 12px;
    padding: 12px;
    width: 15%;
  }
  .mat-tab-label.mat-tab-label-active {
    background-color: $primary-dark;
    color: $on-primary;
    opacity: 1;
    border-radius: 8px;
    i {
      margin-inline-end: 10px;
      color: $on-primary;
      font-size: 18px;
    }
  }
  .mat-tab-body-wrapper {
    width: 90%;
  }
}

.insights {
  .mat-tab-labels {
    display: flex;
    gap: 20px;
  }
  .mat-tab-header {
    border-bottom: none;
  }
  .mat-tab-group.mat-primary .mat-ink-bar {
    display: none;
  }
  .mat-tab-label.mat-tab-label-active {
    border-radius: 8px;
    background: $primary;
    opacity: 1;
    color: $on-primary;
    height: fit-content;
    width: fit-content;
    padding: 0;
    min-width: fit-content;
  }
  .mat-tab-label {
    opacity: 1;
    border-radius: 8px;
    background: $extra-light-background;
    color: $content-main;
    height: fit-content;
    width: fit-content;
    padding: 0;
    min-width: fit-content;
  }
}

.checkout-form {
  .mat-radio-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }
  .mat-radio-button .mat-radio-inner-circle {
    background-color: $primary;
  }
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $primary;
  }
}

.id-form {
  .mat-radio-button .mat-radio-inner-circle {
    background-color: $primary;
  }
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $primary;
  }

  .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple {
    background-color: $primary-color !important;
  }
}

.cpa-container {
  .mat-slider-horizontal {
    min-width: 168px;
    height: 40px;
    & * {
      font-family: Taager;
    }
  }
  .mat-slider-track-background {
    background-color: $on-primary;
  }
  .mat-slider.mat-accent .mat-slider-track-fill {
    background-color: $primary;
  }
  .mat-slider.mat-accent .mat-slider-thumb {
    background-color: $primary;
  }
  .mat-slider-horizontal .mat-slider-track-wrapper {
    height: 9px;
    border-radius: 8px;
  }
  .mat-slider-horizontal .mat-slider-track-background {
    height: 9px;
  }
  .mat-slider-horizontal .mat-slider-track-fill {
    height: 9px;
  }
  .mat-slider-thumb {
    bottom: -14px;
    transform: scale(0.7) !important;
    border: 0.5px solid $on-primary;
  }
  .mat-slider.mat-accent .mat-slider-thumb-label {
    background-color: $content-main;
  }

  .mat-slider-thumb-label {
    transform: rotate(45deg) !important;
    border-radius: 50% 50% 0 !important;
  }

  .mat-slider-thumb-label-text {
    opacity: 1 !important;
  }

  .mat-slider:hover .mat-slider-track-background {
    background-color: $on-primary;
  }

  .mat-slider .mat-slider-track-background {
    background-color: $on-primary;
  }

  .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb {
    background-color: $primary;
  }

  .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused
    .mat-slider-thumb-label {
    background-color: $content-main;
  }

  .slider-direction .mat-slider-track-wrapper {
    transform: rotate(180deg);
  }
}

.cpa-accordion-container {
  .mat-expansion-panel-header {
    background: $light-background;
  }

  .mat-expansion-panel-header.mat-expanded:focus,
  .mat-expansion-panel-header.mat-expanded:hover {
    background: $light-background;
  }
}

.edit-product-page {
  .ql-toolbar.ql-snow {
    direction: ltr;
  }
  .ql-editor li {
    direction: ltr;
  }
  .ql-editor li.ql-align-right {
    direction: rtl;
  }
  .ql-editor li:not(.ql-direction-rtl)::before {
    margin: 0;
    text-align: start;
  }
  .ql-editor ul {
    padding: 0;
  }
}

.options {
  .ql-editor p {
    color: $content-main;
  }
}

.options {
  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: $primary;
  }
  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $on-primary;
  }
}

.track-orders-dialog .dialog-container .mat-expansion-panel-body {
  padding: 0;
}
.track-orders-dialog .mat-expansion-indicator {
  margin-inline-end: 10px;
}
.mat-dialog-container {
  border-radius: 20px !important;
}

.milestone-progress-bar {
  .mat-progress-bar-fill::after {
    background: $loyalty-gold !important;
  }
  .mat-progress-bar-buffer {
    background: $tier-background !important;
  }
}

.referral-code-register {
  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $on-primary !important;
  }
  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: $primary !important;
  }
}

.referral-table-container {
  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: $border-brand !important;
  }
  .back-spinner {
    .mat-progress-spinner circle,
    .mat-spinner circle {
      stroke: $border-brand !important;
      stroke-opacity: 0.3 !important;
    }
  }
}

.align-status {
  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: $brand-bold;
  }
  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $white;
  }
}

.session-token-dialog {
  text-align: start;
}

.product-actions-container {
  .mat-radio-button {
    display: block;
  }
  .mat-radio-label {
    gap: 6px;
    display: flex;
    align-items: center;
  }
  .mat-radio-label-content{
    display: block !important;
  }
  .mat-radio-button .mat-radio-inner-circle {
    background-color: $primary;
  }
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $primary;
  }
  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: white;
    padding: 0 0.75em !important;
  }
  .mat-select-arrow{
    color:$brand-bold !important;
  } 
  .mat-radio-disabled {
    span.mat-radio-outer-circle {
      background-color: $border-light;
      border-color: $border-light;
    }
  }
}
.marketplace-reason.mat-selected  {
  color: $brand-bold !important;
}

.lock-extension-container {
  .mat-radio-button .mat-radio-inner-circle {
    background-color: $primary;
  }
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $primary;
  }
}