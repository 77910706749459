@import '_colors-light';

.pagination {
  margin-top: 12px;
  gap: 16px;
  
  .page-item .page-link {
    border-color: $medium-background;
    color: $content-medium;
    border-radius: 8px;
    padding: 12px 20px;
  }

  .page-item.active .page-link {
    background-color: $primary;
    border-color: $primary;
    color: $on-primary;
  }
}
