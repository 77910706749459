/* General colors*/
$white: #fff;
$dark-gray: #aaa;
$medium-gray: #ddd;
$light-gray: #dde1ed;
$lighter-gray: #f8f8fa;
$minimum-gray: #f7f7fc;
$yellow: #ffae0c;
$green: #3dbb54;
$red: #ff4966;
$dark-red: #990000;
$pink: #ef5da8;
$black: #000;
$dull-blue: #283041;
$email-sms-disclaimer-bg: #c3e5f3;
$email-sms-disclaimer-text: #191e29;
$otp-button-bg: #191e29;

/* Main colors */
$primary-color: #29b6ab;
$secondary-color: #0b1725;
$background-color: $light-gray;

/* Fill colors */
$fill-success: $green;
$fill-danger: $red;
$fill-warn: $yellow;
$fill-white: $white;
$fill-light-gray: $light-gray;
$fill-dark-gray: $dark-gray;
$fill-medium-gray: $medium-gray;
$fill-teal: $primary-color;
$fill-text-area: #f5f5f5;
$fill-account-menu: #f8f8fa;
$fill-notifications-menu-item: #f8f8fa;
$fill-selected-category-side-bar: #f6fcfb;
$fill-icon-container: #e3f4f3;
$fill-information: #f2e9fb;
$fill-success: #e1f4e4;
$fill-error: #f7d7dc;
$fill-warning: #fbefd7;
$fill-form-switcher-card: #dde1ed;

/* Text colors */
$text-light-green: $primary-color;
$text-primary: $secondary-color;
$text-secondary: $dark-gray;
$text-success: $green;
$text-danger: $red;
$text-warn: $yellow;
$text-white: $white;
$text-error: #ce0021;
$text-success: #36a64a;
$text-light: #707685;
$input-border: #d9dbdf;
$text-purple: #ad73e6;
$text-maroon: #b0001c;
$text-orange-highlight: #c27b00;
$secondary-text-light: #586687;

/* Social */
$text-whatsapp: #3ba34e;
$fill-whatsapp: #eff8f1;

$text-facebook: #298cb6;
$fill-facebook: #eef6f9;

$fill-like: #b92e44;

/* Border colors */
$border-color-white: $white;
$border-color-primary: $primary-color;
$border-color-secondary: $secondary-color;
$border-color-light-gray: $light-gray;
$border-color-medium-gray: $medium-gray;
$border-color-dark-gray: $dark-gray;
$border-color-danger: $red;
$border-color-minimum-gray: $minimum-gray;

/* Royalty program colors */
$royalty-silver: #aaaaaa;
$royalty-gold: #e4b779;
$royalty-platinum: #8c9fb7;
$royalty-blue: #298cb6;

/* Order status colors */
$order-received-text: #696969;
$order-received-fill: rgb(10, 23, 38, 0.1);

$order-delievered-text: #0f8981;
$order-delievered-fill: rgb(95, 206, 198, 0.3);

$order-pending-text: #a07706;
$order-pending-fill: rgb(249, 182, 0, 0.3);

$order-cancelled-text: #e31c12;
$order-cancelled-fill: rgb(224, 32, 32, 0.3);

/* Box shadow */
$box-shadow-light: rgba(0, 0, 0, 0.1);
$box-shadow-medium: rgba(0, 0, 0, 0.5);
$box-shadow-gray: rgba(170, 170, 170, 0.51);

/* Catalog */
$original-price-text-orange: #e39700;

/* Variants */
$variants-text: #8062d1;

/* Smart app banners */
$app-banner-background: #0a369d;

/* growth engine*/

$growth-engine-yellow: #ffea94;
$growth-engine-green: #179288;
$growth-engine-dark-green: #38938d;
$growth-engine-pale-blue: #d4e8f0;
$growth-engine-text-blue: #298cb6;

/* Banner Questionnaire */
$dark-green: #016833;
$dark-blue: #0f1933;
$dark-blue-hover: #0f1933e0;
$white-hover: #ffffffe6;
$light-gray-background: #ecedef;

/*
    Auth
*/
$auth-text-dark-one: #262626;
$auth-text-dark-two: #0f1933;
$auth-text-green-one: #46b8b0;
$auth-text-gray-one: #a8acb5;
$auth-text-gray-two: #707685;

$tooltip-background-dark-one: #0b1725;

$yellow-highlight: #d38d00;
$success-status-background: #e2f6e6;
$error-status-background: #f5ccd3;
$warning-status-background: #f9e4cd;
